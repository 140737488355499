(function ($, window, document, undefined) {

  'use strict';

  $(function () {
    
    $('#user-selection').on('change', function() {
      updateSignature( this.value, $('#signature-selection').val() );
    });
    $('#signature-selection').on('change', function() {
      updateSignature( $('#user-selection').val(), this.value );
    });

    $('#copy-signature-code').click(function() {
      copyToClipboard('signature-code');
      var currentCopy = $('#copy-signature-code').html();
      $('#copy-signature-code').html('<i class="bi bi-check"></i> Copied!</button>');
      console.log(currentCopy);
      setTimeout( function() {
        $('#copy-signature-code').html(currentCopy);
        console.log('run', currentCopy);
      }, 1000);
    });
  });

  function updateSignature(userID, signature) {
    $.get('email-signatures/'+ signature +'.php?user=' + userID, function(result) {
        $('#signature-example').html(result);
        $('#signature-code').text(result);
    });
  }


  function copyToClipboard(elementId) {

    // Create a "hidden" input
    var aux = document.createElement("input");
  
    // Assign it the value of the specified element
    aux.setAttribute("value", document.getElementById(elementId).innerHTML);
  
    // Append it to the body
    document.body.appendChild(aux);
  
    // Highlight its content
    aux.select();
  
    // Copy the highlighted text
    document.execCommand("copy");
  
    // Remove it from the body
    document.body.removeChild(aux);
  
  }

})(jQuery, window, document);
